<template>
  <div>
    <div class="newsInfo">
      <div class="lmy-center">
        <div class="lmy-left 222" v-if="flag && !type">
          <div>
            <template
              v-if="tableList.length && tableList[0].mapProperties.erOriginLink"
            >
              <a
                class="lmy-left-top"
                v-if="tableList.length > 0 && pageNum == 0"
                :href="tableList[0].mapProperties.erOriginLink"
                target="_blank"
                style="display: block"
              >
                <div v-if="tableList[0].columnIsTop" class="column_is_top">
                  {{ $t("top") }}
                </div>
                <img :src="tableList[0].thumbnailInfo.thumbnailUrl" />
                <span>{{ $t("newsInformation_3") }}</span>
                <div class="ttnr">
                  <div class="ttnr-left">
                    <p>
                      {{
                        $moment(tableList[0].publishTime)
                          .format("YYYY.MM.DD")
                          .split(".")[2]
                      }}
                    </p>
                    <p>
                      {{
                        $moment(tableList[0].publishTime)
                          .format("YYYY.MM.DD")
                          .slice(0, 7)
                      }}
                    </p>
                  </div>
                  <div class="ttnr-right" @click="toDetail(tableList[0])">
                    <div class="ttnr-right-bt ellipsis2" style="height: 3.33333vw">
                      <a :title="tableList[0].title"
                        >{{ tableList[0].title }}
                      </a>
                    </div>
                    <div class="ttnr-right-zy ellipsis2" style="height: 1.77083vw">
                      {{ tableList[0].description }}
                    </div>
                  </div>
                </div>
              </a>
            </template>
            <template v-else>
              <div
                class="lmy-left-top"
                v-if="tableList.length > 0 && pageNum == 0"
                @click="toDetail(tableList[0])"
              >
                <div v-if="tableList[0].columnIsTop" class="column_is_top">
                  {{ $t("top") }}
                </div>
                <img :src="tableList[0].thumbnailInfo.thumbnailUrl" />
                <span>{{ $t("newsInformation_3") }}</span>
                <div class="ttnr">
                  <div class="ttnr-left">
                    <p>
                      {{
                        $moment(tableList[0].publishTime)
                          .format("YYYY.MM.DD")
                          .split(".")[2]
                      }}
                    </p>
                    <p>
                      {{
                        $moment(tableList[0].publishTime)
                          .format("YYYY.MM.DD")
                          .slice(0, 7)
                      }}
                    </p>
                  </div>
                  <div class="ttnr-right" @click="toDetail(tableList[0])">
                    <div class="ttnr-right-bt ellipsis2" style="height: 3.33333vw">
                      <a :title="tableList[0].title"
                        >{{ tableList[0].title }}
                      </a>
                    </div>
                    <div class="ttnr-right-zy ellipsis2" style="height: 1.77083vw">
                      {{ tableList[0].description }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="lmy-left-bottom">
              <div class="news-list 10">
                <div
                  class="news-list-item"
                  v-for="(item, index) in pageNum == 0
                    ? tableList.slice(1, 9)
                    : tableList"
                  :key="index"
                >
                  <template v-if="item.mapProperties.erOriginLink">
                    <a
                      :href="item.mapProperties.erOriginLink"
                      target="_blank"
                      style="display: block; display: flex"
                    >
                      <div v-if="item.columnIsTop" class="list_is_top">
                        {{ $t("top") }}
                      </div>
                      <div class="list-date">
                        <p>
                          {{
                            $moment(item.publishTime)
                              .format("YYYY.MM.DD")
                              .split(".")[2]
                          }}
                        </p>
                        <p>
                          {{
                            $moment(item.publishTime)
                              .format("YYYY.MM.DD")
                              .slice(0, 7)
                          }}
                        </p>
                      </div>
                      <div class="list-title">
                        <div class="li_bt ellipsis" :title="item.title">
                          {{ item.title }}
                        </div>
                        <p class="li_zy ellipsis">
                          {{ item.description }}
                        </p>
                      </div>
                    </a>
                  </template>
                  <template v-else>
                    <div @click="toDetail(item)" style="display: flex">
                      <div v-if="item.columnIsTop" class="list_is_top">
                        {{ $t("top") }}
                      </div>
                      <div class="list-date">
                        <p>
                          {{
                            $moment(item.publishTime)
                              .format("YYYY.MM.DD")
                              .split(".")[2]
                          }}
                        </p>
                        <p>
                          {{
                            $moment(item.publishTime)
                              .format("YYYY.MM.DD")
                              .slice(0, 7)
                          }}
                        </p>
                      </div>
                      <div class="list-title">
                        <div class="li_bt ellipsis" :title="item.title">
                          {{ item.title }}
                        </div>
                        <p class="li_zy ellipsis">
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-detail" v-if="!flag && type == '文本'">
        <TextDetail :content="currentContent"></TextDetail>
      </div>
      <div class="lmy-page" v-if="flag && !type">
        <Pagination
          @pageChange="pageChange"
          v-if="tableList.length > 0"
          :totalNum="total"
          :currentPage="pageNum"
        ></Pagination>
      </div>
    </div>
    <div class="xgyd w1380" v-if="!flag && (type == '文本' || type == '图片')">
      <p class="fbt">{{ $t("relatedReading") }}</p>
      <p class="bt">
        <i></i>
        <span class="name">{{ $t("relatedReading_1") }}</span>
        <span class="gd"
          ><a @click="more">{{ $t("viewMore") }}&gt;</a></span
        >
      </p>
      <ul class="list">
        <li
          class="list-item"
          v-for="(item, index) in tableList.slice(0, 6)"
          :key="index"
        >
          <div class="date">
            <p>
              {{ $moment(item.publishTime).format("YYYY.MM.DD").split(".")[1]
              }}{{ $t("month_1")
              }}{{ $moment(item.publishTime).format("YYYY.MM.DD").split(".")[2]
              }}{{ $t("day_1") }}
            </p>
            <p>
              {{ $moment(item.publishTime).format("YYYY.MM.DD").split(".")[0]
              }}{{ $t("year_1") }}
            </p>
          </div>
          <div class="list-list" @click="toDetail(item)">
            <a>{{ item.title }}</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination.vue";
import TextDetail from "../textDetail.vue";
export default {
  components: {
    TextDetail,
    Pagination,
  },
  data() {
    return {
      flag: true,
      type: null,
      currentIm: "要闻速递",
      tableList: [],
      pageNum: 0,
      pageSize: 10,
      total: 0,
      currentContent: null,
    };
  },
  created() {
    this.setScroll();
    this.getList();
  },
  methods: {
    wrapLm() {
      this.flag = true;
      this.type = null;
      this.getList("init");
    },
    async getList(init) {
      this.setScroll();
      if (init) {
        this.pageNum = 0;
      }
      this.tableList = [];
      const data = {
        pageNumber: this.pageNum,
        pageSize: this.pageSize,
        columnIds: this.$columnIdFile.ywsd,
      };
      const res = await this.API.basic.getlist(data);
      if (res) {
        this.tableList = res.data.content ? res.data.content : [];
        this.total = res.data.totalElements;
      }
    },
    toDetail(item) {
      this.setScroll();
      this.currentContent = item;
      this.flag = false;
      this.type = "文本";
    },
    // 滚动条初始化高度设置为0
    setScroll() {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 180;
      });
    },
    more() {
      this.flag = true;
      this.type = null;
      this.setScroll();
    },
    pageChange(val) {
      this.pageNum = val - 1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.newsInfo {
  margin: 0 250px;
  background: #fff;
  padding-top: 25px;
}

.lmy-center .lmy-left {
  width: 1030px;
  margin: 0 auto;
}
.lmy-center .lmy-left .lmy-left-top {
  position: relative;
  height: 479px;
}
.navRoute {
  height: 14px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333;
  margin-bottom: 40px;
}
.news-list-item {
  position: relative;
}
.list_is_top {
  position: absolute;
  right: 100px !important;
  top: 0px !important;
  width: 50px;
  color: #fff;
  height: 50px;
  font-size: 16px;
  background: url("../../assets/img/fire.png") no-repeat;
  background-size: 100% 100%;
  line-height: 68px;
  font-weight: bolder;
  text-align: center;
}
.column_is_top {
  position: absolute;
  right: 8px !important;
  top: 10px !important;
  width: 60px;
  color: #fff;
  height: 60px;
  font-size: 20px;
  background: url("../../assets/img/fire.png") no-repeat;
  background-size: 100% 100%;
  line-height: 80px;
  font-weight: bolder;
  text-align: center;
}
.lmy-center .lmy-left .lmy-left-top img {
  width: 100%;
  height: 100%;
}
.lmy-center .lmy-left .lmy-left-top span {
  display: block;
  position: absolute;
  background: url("../../assets/img/topNews.png") no-repeat;
  width: 97px;
  height: 41px;
  font-size: 14px;
  color: #fff;
  top: 30px;
  left: -10px;
  line-height: 50px;
  text-align: center;
}
.ttnr {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  background: url("../../assets/img/maskBg1.png");
  background-repeat: repeat-x;
  color: #fff;
  width: 1030px;
  height: 165px;
  font-size: 12px;
  padding: 50px 135px 0 40px;
}
.ttnr-left {
  width: 60px;
  text-align: center;
  margin-top: 20px;
  margin-right: 28px;
}
.ttnr-left p:first-child {
  font-size: 22px;
}
.ttnr-right {
  width: 760px;
  .ttnr-right-bt {
    margin-bottom: 10px;
  }
}
.ttnr-right a {
  font-size: 22px;
  color: #fff;
}
.ttnr-right a:hover {
  color: #a12916;
}
.lmy-left-bottom {
  margin-top: 25px;
}
.lmy-left-bottom .news-list .news-list-item {
  height: 100px;
  border-top: 1px solid #ddd;
  background: #fff url("../../assets/img/sy-vm-bg-6.png") no-repeat;
  background-position: 95% center;
  padding-left: 50px;
  display: flex;
  align-items: center;
}
.lmy-left-bottom .news-list .news-list-item:first-of-type {
  border-top: none;
}
.lmy-left-bottom .news-list .news-list-item .list-date {
  width: 50px;
  text-align: center;
  color: #a12916;
  margin-right: 50px;
  font-size: 12px;
}
.lmy-left-bottom .news-list .news-list-item .list-date p:first-child {
  font-size: 34px;
}
.lmy-left-bottom .news-list .news-list-item .list-title {
  width: 730px;
  line-height: 180%;
}
.lmy-left-bottom .news-list .news-list-item .list-title .li_bt {
  height: 25px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
  margin-bottom: 10px;
}
.lmy-left-bottom .news-list .news-list-item .list-title .li_zy {
  height: 25px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
}
.lmy-left-bottom .news-list .news-list-item:hover {
  background: #a12916 url("../../assets/img/sy-vm-bg-6.png") no-repeat;
  background-position: 95% center;
  color: #fff;
  .list-date {
    color: #fff;
  }
  .list-title .li_bt {
    color: #fff;
  }
  .list-title .li_zy {
    color: #fff;
  }
}
.lmy-page {
  text-align: center;
  margin-top: 38px;
  padding-bottom: 65px;
}
::v-deep .el-pager li.active {
  background-color: #a12916 !important;
  colre: #fff;
}
::v-deep .el-pager li:hover {
  color: #a12916 !important;
}
::v-deep .el-pagination button:hover {
  color: #a12916 !important;
}
.xgyd {
  margin-top: 50px;
  margin-bottom: 50px;
}
.xgyd .fbt {
  color: #ababab;
  font-size: 24px;
  padding-left: 5px;
}
.xgyd .bt {
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
}
.xgyd .bt i {
  float: left;
  width: 4px;
  height: 30px;
  background: #ad2e1b;
  border-radius: 2px;
}
.xgyd .bt .name {
  font-size: 28px;
  color: #000;
  float: left;
  margin-left: 5px;
}
.xgyd .bt .gd {
  float: right;
  font-size: 16px;
  color: #a12916;
}
.xgyd .bt .gd a {
  float: right;
  font-size: 16px;
  color: #a12916;
}
.xgyd .list {
  display: flex;
  flex-wrap: wrap;
}
.xgyd .list li {
  width: 672.5px;
  height: 108px;
  background: #fff url("../../assets/img/jt.png") no-repeat;
  background-position: 95% center;
  margin-top: 20px;
  padding: 10px 20px;
}
.xgyd .list li:nth-of-type(2n + 1) {
  margin-right: 35px;
}
.xgyd .list li .date {
  float: left;
  margin-top: 17px;
}
.xgyd .list li .date p {
  font-size: 18px;
  height: 30px;
  line-height: 30px;
  width: 80px;
  text-align: center;
}
.xgyd .list li .date p:first-child {
  border-bottom: 2px solid #f9f9f9;
}
.xgyd .list li .list-list {
  float: left;
  height: 80px;
  margin-left: 20px;
  margin-top: 7px;
  border-left: 2px solid #f9f9f9;
  width: 440px;
  padding-top: 10px;
  line-height: 30px;
  padding-left: 10px;
  font-size: 18px;
  a{
    color: #000;
  }
}
.xgyd .list li:hover {
  width: 672.5px;
  height: 108px;
  background: #a12916 url("../../assets/img/jt.png") no-repeat;
  background-position: 95% center;
  margin-top: 20px;
  padding: 10px 20px;
  color: #fff;
  a {
    color: #fff;
  }
}
</style>
